.footer {
  background-color: #1e1e1e;
  color: #eee;
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  border-radius: 8px;
  padding: 20px 25px;
}

.footer .footer-copyright {
  font-size: small;
  text-align: end;
  color: #9c9c9c;
}

.footer .footer-copyright > a {
  text-decoration: none !important;
  color: var(--main-color) !important;
}

.footer .footer-right {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.footer .footer-right > a > .MuiSvgIcon-root {
  font-size: 24px;
  margin-right: 24px;
  color: var(--main-color);
}
