.profile {
  background-color: white;
  border-radius: 6px;
  width: 100%;
  display: inline-block;
}

.profile-name {
  line-height: 18px;
  padding: 20px;
}

.profile-name .name {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
}

.profile-name .title {
  font-size: 14px;
  color: #777;
}

.profile-image {
  max-height: 400px;
  margin-top: -10px;
  --webkit-clip-path: polygon(0 9%, 100% 0, 100% 94%, 0% 100%);
  clip-path: polygon(0 9%, 100% 0, 100% 94%, 0% 100%);
}

.profile-image img {
  width: 100%;
}

/* Profile Information */

.profile-information {
  top: 0;
  left: 0;
  margin: -40px 10px 30px;
}

.profile-information .timeline .timeline-content {
  margin-top: 2px;
  margin-bottom: 12px;
}

.timelineItem-text {
  font-size: 15px !important;
  color: #787878;
  font-weight: 400 !important;
}

.timelineItem-text > a {
  text-decoration: none;
}

.timelineItem-text > span {
  color: #000;
}

.profile .button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

@media (min-width: 1280px) {
  .MuiGrid-grid-lg-3 {
    max-width: 27% !important;
    flex-basis: 27% !important;
  }
}
