/* tabs */

.customTabs .active {
  color: var(--main-color) !important;
}

.customTabs .customTabs-item {
  text-transform: inherit;
  font-size: 14px;
  font-weight: 400;
  min-width: 30px;
  max-width: 110px;
  border-radius: 35px;
  padding: 3px 6px;
}

/* end of tabs */

/* cards */
.customCard {
  height: 100%;
  box-shadow: 0px 0px 48px 0px rgba(4, 6, 4, 0.2) !important;
  border-radius: 5px;
  overflow: hidden;
  outline-width: 0px !important;
}
.customCard .customCard-image {
  height: 140px;
}
.customCard .customCard-title {
  font-weight: 700 !important;
}
.customCard .customCard-caption {
  color: gray !important;
  font-weight: 300;
}

.MuiDialogContent-root {
  padding: 8px 12px !important;
}

/* end of cards */

/* dialog */
/* .projectDialog {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 800px;
} */
.projectDialog .projectDialog-title .MuiTypography-h6 {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.projectDialog .MuiDialogTitle-root {
  padding: 0px 12px 6px 12px;
}

.projectDialog .projectDialog-closeButton {
  text-decoration: none;
  color: black;
  font-size: 24px;
  margin-right: -4px;
  height: fit-content;
  cursor: pointer;
  font-weight: 500;
}
.projectDialog .projectDialog-image {
  max-height: 800px;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}
.projectDialog .projectDialog-description {
  max-width: 700px;
  margin: auto;
  margin-top: 20px;
  text-align: center;
}
.projectDialog .projectDialog-actions {
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.projectDialog .projectDialog-icon .btn-text {
  font-size: 14px !important;
}

@media (max-width: 959px) {
  .projectDialog .projectDialog-closeButton {
    font-size: 30px;
  }
}

/* .projectDialog .projectDialog-icon :hover {
  color: var(--main-color);
} */
/* .projectDialog .projectDialog-icon > .MuiSvgIcon-root {
  font-size: 40px !important;
} */

/* end of dialog */
