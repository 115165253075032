.timeline .MuiTimeline-root {
  padding: 0;
}
.timeline {
  padding: 0 !important;
}

.timeline .timeline-header {
  padding-top: 7px;
}

.timeline .timeline-firstItem {
  min-height: 80px !important;
}

.timeline .MuiTimelineItem-root {
  /* padding-left: 10px; */
  min-height: 0px;
}

.timeline .MuiTimelineItem-missingOppositeContent:before {
  display: none;
}
.timeline .timeline-dot-header {
  color: black;
  background-color: var(--main-color);
  font-size: smaller;
  padding: 12px;
}

/* Remaining items */

.timeline .timeline-dot {
  border-color: var(--main-color);
  background-color: #eee;
  padding: 2px;
}

.timeline .MuiTimelineConnector-root {
  background-color: #eee;
}

.timeline .timeline-dot-header > .MuiSvgIcon-root {
  font-size: 20px;
  color: black;
}

.timeline .MuiTimeline-root {
  padding: 0px;
}
.timeline .separator-padding {
  padding-left: 20px;
}

.timeline .timeline-content {
  padding-top: 0px;
  margin-bottom: 20px;
}
