/* Button styling for the button blocks */
.button {
  padding: 10px 20px; /* Reduced padding to make buttons more compact */
  font-size: 14px; /* Smaller font size for better responsiveness */
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  line-height: 50px; /* Adjust line-height for compact buttons */
  width: 100%; /* Full width within each grid column */
  max-width: 100px; /* Limit button width */
}

/* Adjustments for mobile screens */
@media (max-width: 600px) {
  .button {
    padding: 8px 16px; /* Reduce padding further for smaller screens */
    font-size: 12px; /* Smaller font size on mobile */
    max-width: 80px; /* Limit max-width for smaller buttons */
  }
}
