:root {
  --main-color: #dd9c42;
}

/* Margins and paddings */
.top-60 {
  margin-top: 60px;
}

.top-30 {
  margin-top: 30px;
}

.bottom-20 {
  margin-bottom: 30px !important;
}

.bottom-30 {
  margin-bottom: 30px !important;
}

.bottom-60 {
  margin-bottom: 60px !important;
}

.h-1000 {
  height: 1000px;
}

.p-30 {
  padding: 30px;
}

.p-50 {
  padding: 50px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-45 {
  padding-top: 45px;
}

.pb-30 {
  padding-bottom: 30px;
}
.pb-45 {
  padding-bottom: 45px;
}

.app {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.App-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px;
}
.container-shadow {
  box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
  --webkit-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
  --moz-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
}

.main-content {
  background-color: #fff;
  /* min-height: 100vh; */
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  margin-bottom: 30px;
  /* padding: 30px; */
}

.section {
  padding-left: 30px;
  padding-right: 30px;
}

.section-title {
  position: relative;
  display: inline-block;
  padding: 0 3px;
}

.section-title .section-title-text {
  font-size: 18px;
  font-weight: 600;
  position: relative;
  margin: 0;
}

.section-title span {
  position: absolute;
  height: 7px;
  width: 100%;
  bottom: 0;
  opacity: 0.4;
  left: 0;
  background-color: var(--main-color);
}

.graybg {
  background-color: #fafafa;
}

@media (max-width: 959px) {
  .top-60 {
    margin-top: 30px;
  }
  .App {
    background-position: center center;
  }

  .App-content {
    align-items: flex-end;
    justify-content: center;
  }
}
