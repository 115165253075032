/* about me section */
.aboutme-text {
  color: darkslategray;
  font-size: smaller;
  line-height: 22px !important;
  white-space: pre-line;
}

/* End of about me section */

/* work experience section */
.resume-timeline .timeline-content .timeline-title {
  font-size: 16px;
  font-weight: 500;
}

.resume-timeline .timeline-content .timeline-date {
  color: darkslategray;
}

.resume-timeline .timeline-content .timeline-description {
  color: gray;
  font-size: small;
  margin-top: 6px;
}
.resume-timeline .timeline-content .timeline-sub-section {
  margin-top: 10px;
}
.resume-timeline .timeline-content .timeline-sub-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.resume-timeline .timeline-content .timeline-sub-item-title {
  font-size: 14px;
  margin: 2px 12px;
}
.resume-timeline .timeline-content .timeline-sub-item-text {
  color: gray;
  font-size: small;
  margin-top: 6px;
  margin-left: 14px;
}

/* End of work experience section */

/* Services Section */
.service {
  height: 100%;
  text-align: center;
}

.service .service-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
}

.service .service-description {
  color: #989898 !important;
  border-color: var(--main-color) !important;
  margin-right: 5px !important;
  text-align: center;
}

.service-icon > .MuiSvgIcon-root {
  font-size: 46px !important;
  margin-left: 0 !important;
  font-weight: 300 !important;
  color: black !important;
}

/* End of Services Section */

/* skill section */

.skill {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 0px 48px 0px rgba(4, 6, 4, 0.08) !important;
  min-height: 186px;
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
}

.skill .skill-title {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 35px !important;
  text-transform: uppercase;
}

.skill .skill-description {
  display: flex;
  align-items: center;
  color: #989898;
  font-weight: 400 !important;
  line-height: 18px !important;
}

.skill .skill-description .skill-dot {
  border-color: var(--main-color);
  padding: 2px;
  margin-right: 5px;
}

/* end of skill section */

/* Contact Section */

/* End of Contact Section */
