* {
  box-sizing: border-box;
  margin: 0px;
}

body {
  font-family: Georgia, "Times New Roman", Times, serif;
  margin: 0px;
  padding: 0;
}
h1 {
  margin-top: 0;
  font-size: 22px;
}

h2 {
  margin-top: 0;
  font-size: 20px;
}

h3 {
  margin-top: 0;
  font-size: 18px;
}

h4 {
  margin-top: 0;
  font-size: 16px;
}

h5 {
  margin-top: 0;
  font-size: 14px;
}

h6 {
  margin-top: 0;
  font-size: 12px;
}

code {
  font-size: 1.2em;
}

ul {
  padding-inline-start: 20px;
}

* {
  box-sizing: border-box;
}

.container {
  margin: 1rem;
  text-align: center;
}
.board-row {
  width: 300px;
}

.pokemonImage {
  margin-top: 10px;
  width: 200px;
  height: 150px;
}

.pokemonName {
  font-size: 24px;
  margin-top: 20px;
}

.button-Pokemon {
  margin-top: 20px;
  font-size: 20px;
  width: 250px;
  height: 50px;
}

.game {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
}
