/* Container for the whole game */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* Keeps the container positioned correctly */
}

/* Header section for the Start/Next buttons */
.header-button {
  width: 100%; /* Full width */
  display: flex;
  justify-content: center; /* Center the buttons */
  gap: 10px;
  margin-bottom: 20px; /* Add some space between the header and the game */
}

/* Button container with 3-column grid */
.buttons-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 fixed columns */
  gap: 10px;
  width: 90%; /* Make the buttons container take up most of the screen width */
  max-width: 400px; /* Limit the maximum width to make it better on larger screens */
  justify-items: center;
}

/* Basic button styling */
button {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;

}

button:focus {
  outline: none;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}



/* Styling for the start and next game buttons */
.start-button, .next-game-button {
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #4caf50;
  color: white;
}

.next-game-button {
  background-color: #2363ca;
}

.progress-game-button {
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #d3c916;
  cursor: not-allowed;
  pointer-events: none;
}

.start-button:hover, .next-game-button:hover {
  background-color: #45a049;
}

/* Difficulty dropdown section */
.difficulty-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

/* Text styling */
p {
  font-size: 18px;
  text-align: center;
  margin: 5px 0;
}

/* Confetti overlay styles */
.confetti-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Slight overlay to make confetti stand out */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the confetti is above everything else */
  opacity: 1;
  animation: fadeOut 5s forwards; 
}

/* Keyframes for fade-out effect */
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

/* Zoom the confetti gif */
.confetti-overlay img {
  width: 100%; /* Stretch the width to 100% */
  height: 100%; /* Stretch the height to 100% */
  object-fit: cover; /* Ensure the image covers the area and can be zoomed */
  pointer-events: none; /* Prevent interaction with the confetti image */
}



/* Responsive adjustments for mobile screens */
@media (max-width: 600px) {
  .buttons-container {
    grid-template-columns: repeat(3, 1fr); /* Keep 3 columns even on small screens */
    width: 100%; /* Full width on small screens */
    max-width: none; /* Remove max-width */
  }
}
