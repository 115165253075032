* {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: Georgia, "Times New Roman", Times, serif;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

code {
  font-size: 1.2em;
}

ul {
  padding-inline-start: 20px;
}

.container {
  margin: 1rem;
  text-align: center;
}

.square {
  background: #fff;
  border: 2px solid #999;
  font-weight: 100;
  height: 25vw; /* Height adjusts based on viewport width */
  width: 25vw;  /* Width adjusts based on viewport width */
  margin-right: -1px;
  margin-top: -1px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-Game {
  font-size: 20px;
  width: 70%;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.board-row {
  display: flex;
  justify-content: center;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.board {
  display: flex;
  flex-wrap: wrap;
  width: 90%; /* Use a flexible width for the board */
  max-width: 400px; /* Limit the max width to ensure it doesn’t stretch too much on large screens */
  margin: auto;
  justify-content: center;
  align-items: center;
}

.status {
  margin-top: 20px;
  font-size: 5vw; /* Make the status text font size responsive */
  text-align: center;
  margin-bottom: 10px;
}

.game {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 90%; /* Use a percentage width to make the entire game responsive */
  margin: auto;
}

.game-info {
  margin-left: 20px;
}

/* Media queries for smaller screens */
@media (max-width: 600px) {
  .square {
    font-size: 10vw; /* Make font larger on smaller screens */
    height: 25vw;
    width: 25vw;
  }

  .status {
    font-size: 6vw; /* Adjust status font size for small screens */
  }

  .button-Game {
    width: 90%; /* Make the button more responsive on small screens */
  }
}

@media (max-width: 400px) {
  .square {
    font-size: 12vw; /* Make squares even bigger for very small screens */
    height: 25vw;
    width: 25vw;
  }

  .status {
    font-size: 7vw; /* Adjust status text for very small screens */
  }
}

/* Media query for larger screens (Desktop) */
@media (min-width: 1024px) {
  .square {
    height: 100px;  /* Set a fixed height for squares */
    width: 100px;   /* Set a fixed width for squares */
    font-size: 48px; /* Much larger font size for X/O on desktop */
  }

  .board {
    max-width: 400px;
    padding: 0;
  }

  .status {
    font-size: 24px;
  }

  .button-Game {
    width: 250px;
  }

  /* Adjust the overall game height on desktop */
  .game {
    max-height: 600px; /* Prevent the board from stretching too tall */
    height: auto;
  }
}
