* {
  box-sizing: border-box;
  margin: 0px;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
  padding: 0;
  background: #fafafa;
  overflow-x: hidden;
  background: url("./media/bg_wide.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Manaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 959px) {
  body {
    background: url("./media/bg_half_tall.png");
  }
}
