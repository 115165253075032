.header {
  margin-bottom: 30px;
  background-color: white;
  padding: 0 22px 0 0;
  border-radius: 6px;
  box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.07);
}

.header .header-link {
  font-weight: 500;
  color: black;
  font-size: 18px;
}

.navbar-brand {
  margin-right: 0;
}

.header .header-link:hover {
  color: var(--main-color);
}

.header-link-active,
.header-link {
  margin-right: 10px;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  font-weight: 500;
  color: #dd9c42 !important;
}

.header .header-home {
  background-color: var(--main-color);
  padding: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.header .header-right {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.header .header-right > a > .MuiSvgIcon-root {
  font-size: 24px;
  margin-right: 24px;
  color: black;
}

.header .header-left {
  margin-left: 5px;
  margin-right: auto;
}

@media (max-width: 959px) {
  .header {
    padding: 0;
  }
  .navbar-nav {
    flex-direction: row;
  }
  .header .header-left {
    margin-left: 5px;
  }
  .header .header-link {
    font-size: 16px;
    margin: 0 10px;
  }
  .header .header-right {
    display: none;
  }
}
